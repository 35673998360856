import axios from "axios";

// Define your API base URL as a constant to reuse it.
const API_BASE_URL = "http://api.consultarer.com/api"; // Change this to your actual API URL
// const API_BASE_URL = "http://consultarer-api.test/api"; // Change this to your actual API URL

// Define a function to save an email to the database.
export const saveEmail = async (email, areaOfInterest) => {
  let isStored = false;

  let data = JSON.stringify({
    email,
    areaOfInterest,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_BASE_URL}/saveEmail`,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer c775e7b757ede630cd0aa1113bd102661ab38829ca52a6422ab782862f268646",
    },
    data: data,
  };

  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      isStored = true;
    })
    .catch((error) => {
      console.log(error);
    });
  // Return false to indicate that the operation failed.
  return isStored;
};
