import React, { useState } from 'react';

import { Form, Button, Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import { FaFacebook, FaLinkedin, FaYoutube, FaTwitter } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { BsCheckCircle } from 'react-icons/bs';

import { saveEmail } from "../../app/services/emailService";

import ImagePath from '../../resources/images/Mockup.png';
import LogoPath from '../../resources/images/Logo.png';

const LandingPageContent = () => {
  const [isJoined, setIsJoined] = useState(false);
  const baseUrl = window.location.origin;
  const [email, setEmail] = useState("");
  const [areaOfInterest, setAreaOfInterest] = useState("Accounting");

  const handleSaveEmail = async () => {
    // validate email
    if (!email) {
      alert("Please enter your email address");
      return;
    }
    // validate regex email
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address");
      return;
    }
    if (!areaOfInterest) {
      alert("Please select your area of interest");
      return;
    }
    const isSaved = await saveEmail(email, areaOfInterest);
    setIsJoined(isSaved);
  };

  return (
    <Container fluid>
      <Row>
        <Row className="relative-position">
          <a href={`${baseUrl}`}>
            {" "}
            <Image
              src={LogoPath}
              alt="Logo"
              className="mb-2 mt-4 logo top-left-logo"
            />
          </a>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={6} className="order-2 order-lg-1">
            <Container className="overlap-right" fluid>
              {isJoined ? (
                <div>
                  <p className="mb-5 header-text">
                    Waitlist Request{" "}
                    <BsCheckCircle
                      color="#7CE7A7"
                      className="mb-3 success-check"
                    />
                    Confirmation
                  </p>
                  <p className="mb-5 para-text">
                    Thanks for your interest in joining our beta waitlist. Being
                    on the wait list guarantees you early access to the product
                    and its promising features.
                  </p>

                  <p className="mb-5 endsuccess-text">
                    Expect our email with links to download the Android and IOS
                    applications in a few weeks.
                  </p>

                  <p className="mb-5 contact-text">
                    For additional questions/concerns, Connect Here:{" "}
                    <span className="make-underline">
                      contact@consultarer.com{" "}
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="mb-4 header-text">
                    Taking Work and Connection All Virtual
                  </p>
                  <p className="mb-4 para-text">
                    Join our exclusive beta testing and witness firsthand the
                    revolutionary tools that will redefine the way you{" "}
                    <span className="contrast-text">
                      connect, share, and earn.
                    </span>
                  </p>
                  <Form.Group className="mb-4 input-text">
                    <Form.Control
                      className="mb-4 input-text"
                      type="text"
                      placeholder="Enter your email address *"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4 input-text">
                  <Form.Control
                      as="select"
                      defaultValue="option1"
                      onChange={(e) => setAreaOfInterest(e.target.value)}
                      value={areaOfInterest}
                    >
                      <option value="option1" disabled>
                        Choose your industry/interest *
                      </option>
                      <option>Accounting</option>
                      <option>Advertising, Marketing and Sale</option>
                      <option>Agriculture and Natural Resources</option>
                      <option>Arts and Design</option>
                      <option>Aviation</option>
                      <option>Business</option>
                      <option>E-commerce</option>
                      <option>Education</option>
                      <option>Entertainment</option>
                      <option>Food</option>
                      <option>Fashion and Beauty</option>
                      <option>Governing and Public Safety</option>
                      <option>Law</option>
                      <option>Science and Engineering</option>
                      <option>Physical and Mental Health</option>
                      <option>Sport</option>
                      <option>Social Media</option>
                    </Form.Control>
                  </Form.Group>

                  <Button
                    className="mb-4 join-button"
                    variant="dark"
                    onClick={() => handleSaveEmail()}
                  >
                    Join Waitlist
                  </Button>

                  <p className="mb-4 small-text">
                    We respect your privacy, by joining our email list you agree
                    to receive email updates and communications related to the
                    beta testing program in accordance with our
                    <a href={`${baseUrl}/privacypolicy`}>
                      {" "}
                      <span className="smallpara-text">privacy policy</span>
                    </a>
                  </p>
                </div>
              )}
            </Container>
          </Col>

          <Col xs={12} md={12} lg={6} className="order-1 order-lg-2">
            <Container className="">
              <Image src={ImagePath} alt="Descript" fluid />
            </Container>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} md={12} lg={8} className="order-2 order-lg-1 end-text">
            <Container className="overlap-bottom-right" fluid>
              <p>
                Ready to invest in our vision?{" "}
                Explore investment{" "}
                opportunities and become a part of our promising future.{" "}
                <span className="make-bold">
                  <br className="hide-on-mobile" />
                  <br className="hide-on-mobile" />
                  Contact us: <a className="make-grey" href="mailto:investments@consultarer.com">investments@consultarer.com</a>
                </span>
              </p>
            </Container>
          </Col>

          <Col
            xs={12}
            md={12}
            lg={4}
            className="order-2 order-lg-2 social-text"
          >
            <a
              href="https://www.facebook.com/consultarer"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              <FaFacebook className="social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/consultarer/"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              <FaLinkedin className="social-icon" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC1XI-vUmOibLgOSQgGX8MJQ"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              <FaYoutube className="social-icon" />
            </a>
            <a
              href="https://twitter.com/consultarer"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              <FaTwitter className="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/consultarer"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              <RiInstagramFill className="social-icon" />
            </a>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default LandingPageContent;

